import {
  IoEye,
  IoEar,
  IoBook,
  IoCreate,
  IoSchool,
  IoInformationCircle,
  IoEllipsisHorizontal,
  IoCall,
  IoChatbox,
  IoMail,
  IoHelp,
  IoCart,
  IoHandRight,
  IoCar,
  IoClose,
  IoEyeOutline,
  IoEarOutline,
  IoBookOutline,
  IoCreateOutline,
  IoSchoolOutline,
  IoInformationCircleOutline,
  IoEllipsisHorizontalOutline,
  IoCallOutline,
  IoChatboxOutline,
  IoMailOutline,
  IoHelpOutline,
  IoCartOutline,
  IoHandRightOutline,
  IoCarOutline,
  IoCloseOutline,
  IoBusiness,
  IoBusinessOutline,
  IoFootsteps,
  IoFootstepsOutline,
  IoWalk,
  IoWalkOutline,
  IoContrast,
  IoContrastOutline,
  IoStopwatch,
  IoStopwatchOutline,
  IoMoon,
  IoMoonOutline,
  IoLogoTwitter,
  IoCalendar,
  IoCalendarOutline,
  IoPerson,
  IoPersonOutline,
  IoLocationSharp,
  IoLocationOutline,
  IoHourglassOutline,
  IoHourglass,
  IoClipboardOutline,
  IoClipboard,
} from "react-icons/io5";
import { GiPalmTree } from "react-icons/gi";
import { BsSunrise, BsSunset } from "react-icons/bs";

const getIconByName: any = (iconName: string) => {
  switch (iconName) {
    case "IoEye":
      return IoEye;
    case "IoEar":
      return IoEar;
    case "IoBook":
      return IoBook;
    case "IoCreate":
      return IoCreate;
    case "IoSchool":
      return IoSchool;
    case "IoInformationCircle":
      return IoInformationCircle;
    case "IoEllipsisHorizontal":
      return IoEllipsisHorizontal;
    case "IoCall":
      return IoCall;
    case "IoChatbox":
      return IoChatbox;
    case "IoMail":
      return IoMail;
    case "IoHelp":
      return IoHelp;
    case "IoCart":
      return IoCart;
    case "IoHandRight":
      return IoHandRight;
    case "IoCar":
      return IoCar;

    case "BsSunrise":
      return BsSunrise;
    case "IoContrastOutline":
      return IoContrastOutline;
    case "IoStopwatch":
      return IoStopwatch;
    case "IoContrast":
      return IoContrast;
    case "BsSunset":
      return BsSunset;
    case "IoMoon":
      return IoMoon;
    case "IoWalk":
      return IoWalk;
    case "IoBusiness":
      return IoBusiness;
    case "IoFootsteps":
      return IoFootsteps;
    case "IoLogoTwitter":
      return IoLogoTwitter;
    case "IoCalendar":
      return IoCalendar;
    case "GiPalmTree":
      return GiPalmTree;
    case "IoPerson":
      return IoPerson;
    case "IoLocation":
      return IoLocationSharp;
    case "IoHourglass":
      return IoHourglass;
    case "IoClipboard":
      return IoClipboard;
    default:
      return IoClose;
  }
};

const getIconOutlineByName: any = (iconName: string) => {
  switch (iconName) {
    case "IoEye":
      return IoEyeOutline;
    case "IoEar":
      return IoEarOutline;
    case "IoBook":
      return IoBookOutline;
    case "IoCreate":
      return IoCreateOutline;
    case "IoSchool":
      return IoSchoolOutline;
    case "IoInformationCircle":
      return IoInformationCircleOutline;
    case "IoEllipsisHorizontal":
      return IoEllipsisHorizontalOutline;
    case "IoCall":
      return IoCallOutline;
    case "IoChatbox":
      return IoChatboxOutline;
    case "IoMail":
      return IoMailOutline;
    case "IoHelp":
      return IoHelpOutline;
    case "IoCart":
      return IoCartOutline;
    case "IoHandRight":
      return IoHandRightOutline;
    case "IoCar":
      return IoCarOutline;
    case "IoBusiness":
      return IoBusinessOutline;
    case "IoFootsteps":
      return IoFootstepsOutline;
    case "IoWalk":
      return IoWalkOutline;

    case "BsSunrise":
      return BsSunrise;
    case "IoContrastOutline":
      return IoContrastOutline;
    case "IoStopwatch":
      return IoStopwatchOutline;
    case "IoContrast":
      return IoContrast;
    case "BsSunset":
      return BsSunset;
    case "IoMoon":
      return IoMoonOutline;
    case "IoWalk":
      return IoWalkOutline;
    case "IoBusiness":
      return IoBusinessOutline;
    case "IoFootsteps":
      return IoFootstepsOutline;
    case "IoLogoTwitter":
      return IoLogoTwitter;
    case "IoCalendar":
      return IoCalendarOutline;
    case "GiPalmTree":
      return GiPalmTree;
    case "IoPerson":
      return IoPersonOutline;
    case "IoLocation":
      return IoLocationOutline;
    case "IoHourglass":
      return IoHourglassOutline;
    case "IoClipboard":
      return IoClipboardOutline;
    default:
      return IoCloseOutline;
  }
};

export { getIconByName, getIconOutlineByName };
