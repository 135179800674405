import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Divider,
  Grid,
  GridItem,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { deleteNote, getNotes, updateNote } from "api/notes";
import React, { useEffect, useRef, useState } from "react";
import { formatDate } from "utils/formatDate";
import getFromNestedObject from "utils/getFromNestedObject";
import getSortedArrayOfObjects from "utils/getSortedArrayOfObjects";
import { getIconByName, getIconOutlineByName } from "./forms/base_new_note/getIconsDynamically";
import FilterForm from "./forms/FilterForm";
import ListsForm from "./forms/ListsForm";
import CategoriesSidebar from "./sidebars/CategoriesSidebar";

const propsByImportance: any = (importance: number) => {
  switch (importance) {
    case 10:
      return { fontWeight: "normal", fontStyle: "italic" };
    case 20:
      return { fontWeight: "normal" };
    case 30:
      return { fontWeight: "bold" };
    case 40:
      return { fontWeight: "bold", color: "red.200" };
    default:
      throw "Wrong importance number";
  }
};

const categoriesIcons: any = {
  do: "IoHandRight",
  watch: "IoEye",
  listen: "IoEar",
  read: "IoBook",
  write: "IoCreate",
  learn: "IoSchool",
  check: "IoHelp",
  info: "IoInformationCircle",
  travel: "IoCar",
  shop: "IoCart",
  call: "IoCall",
  message: "IoChatbox",
  mail: "IoMail",
};

const sortOptions: any = {
  createdOn: ["sys", "createdAt"],
  tag: ["fields", "note", "en-US", "tag"],
  importance: ["fields", "note", "en-US", "importance"],
  category: ["fields", "note", "en-US", "category"],
  dayPeriod: ["fields", "note", "en-US", "day_period"],
  duration: ["fields", "note", "en-US", "duration"],
  location: ["fields", "note", "en-US", "location"],
};

const listBlock1 = ["GENERAL"];
const listBlock2 = ["everything else here, and later hardcode to the right place"];
const listBlock3 = ["a-note", "deployflow", "diary", "kadalis"];

const NotesList = ({ onOpen, setterDefaulNoteVal, globalNotesLists }: any) => {
  const ref: any = useRef(null);
  const [notesList, setNotesList]: any = useState(null);
  const [sortedList, setSortedList]: any = useState(null);
  const [isError, setIsError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [sortBy, setSortBy] = useState(sortOptions.createdOn);
  const [reverseSort, setReverseSort] = useState(true);
  const [filterValues, setFilterValues] = useState(null);
  const [categories, setCategories] = useState(null);
  const [notesLists, setNotesLists]: any = useState([]);
  const [activeListName, setActiveListName] = useState("GENERAL");
  const [isEditActive, setIsEditActive] = useState(false);
  const [sidebarLeft, setSidebarLeft] = useState(false);
  const [textInFocus, setTextInFocus] = useState(false);
  const [lastId, setLastId] = useState("");
  const modal2 = useDisclosure();
  const modal3 = useDisclosure();

  const runGet = async () => {
    const res: any = await getNotes();
    if (res && res.status == "200") {
      setNotesList(res.data.items);
      // setSortedList(res.data.items);
      setSortedList([...res.data.items].filter((el: any) => "GENERAL" === getFromNestedObject(el, sortOptions.tag)));
      // prepare filter categories and values
      let fvalues: any = {
        tag: new Set(),
        importance: new Set(),
        location: new Set(),
      };
      let categories: any = new Set();

      res.data.items.map((obj: any) => {
        const note = obj.fields.note["en-US"];
        fvalues["tag"].add(note["tag"]);
        fvalues["importance"].add(note["importance"]);
        fvalues["location"].add(note["location"]);
        categories.add(note["category"]);
      });
      for (let key in fvalues) {
        // sort items in set
        fvalues[key] = new Set(
          [...fvalues[key]].sort((a: any, b: any) => `${a}`.localeCompare(`${b}`, undefined, { sensitivity: "base" }))
        );
      }
      setFilterValues(fvalues);
      // sort items in set
      categories = new Set(
        [...categories].sort((a: any, b: any) => `${a}`.localeCompare(`${b}`, undefined, { sensitivity: "base" }))
      );
      setCategories(categories);
      //create notes lists
      let lists: any = new Set();
      res.data.items.map((obj: any) => {
        lists.add(obj.fields.note["en-US"]["tag"]);
      });
      let final: any = [...lists].filter((e: any) => e !== "GENERAL");
      // uncomment below funciton if List Blocks will not be used:
      // final.sort((a: any, b: any) => a.localeCompare(b, undefined, { sensitivity: "base" }));

      // START list blocks
      // list 1 is for now just 'general', maybe later there will be some other default lists like 'doing now'
      let list3: any = [...final].filter((e: any) => listBlock3.includes(e));
      let list2: any = [...final].filter((e: any) => !listBlock3.includes(e)); // list 2 is the rest
      // END list blocks

      // setNotesLists(final); // if List Blocks not used
      setNotesLists([...list2.reverse(), ...list3]);
      globalNotesLists(final);
    }
  };

  const handleCompleteNote = async (entryId: any, version: number, obj: any, isDone: boolean) => {
    try {
      setIsError(false);
      setProcessing(true);
      const res = await updateNote(entryId, version, {
        ...getFromNestedObject(obj, ["fields", "note", "en-US"]),
        is_done: isDone,
      });
      if (res) {
        runGet();
      }
    } catch (error: any) {
      console.log(error);
      setIsError(true);
    } finally {
      setProcessing(false);
    }
  };

  const handleCompleteSubnote = async (
    entryId: any,
    version: number,
    obj: any,
    subnoteIndex: number,
    isDone: boolean
  ) => {
    try {
      setIsError(false);
      setProcessing(true);
      let newObj = { ...getFromNestedObject(obj, ["fields", "note", "en-US"]) };
      newObj.subtexts[subnoteIndex]["is_done"] = isDone;
      const res = await updateNote(entryId, version, { ...newObj });
      if (res) {
        runGet();
      }
    } catch (error: any) {
      console.log(error);
      setIsError(true);
    } finally {
      setProcessing(false);
    }
  };

  const handleDeleteNote = async (entryId: any, version: number) => {
    try {
      setIsError(false);
      setProcessing(true);
      const res = await deleteNote(entryId, version);
      if (res) {
        runGet();
      }
    } catch (error: any) {
      console.log(error);
      setIsError(true);
    } finally {
      setProcessing(false);
    }
  };

  function filterNotes(filterBy: string, filterCategory: any[]) {
    if (filterBy === "ALL") {
      setSortedList([...notesList]);
    } else {
      setSortedList([...notesList].filter((el: any) => filterBy === getFromNestedObject(el, filterCategory)));
    }
    setActiveListName(filterBy);
  }

  const filterNotes2 = (obj: any) => {
    let nl = [...notesList];
    Object.entries(obj).map(([key, set]: any) => {
      nl = nl.filter((el: any) => {
        return set.has(getFromNestedObject(el, sortOptions[key]));
      });
    });
    setSortedList(nl);
  };

  const filterForCategoriesSidebar = (category: string) => {
    if (category === "all") {
      setSortedList([...notesList]);
    } else {
      let nl = [...notesList];
      nl = nl.filter((el: any) => getFromNestedObject(el, sortOptions["category"]) === category);
      setSortedList(nl);
    }
  };

  const handleShowHideBtns = (id: string) => {
    if (lastId) {
      const res = document.getElementById(lastId);
      res && (res.style.display = "none");
    }
    const res = document.getElementById(id);
    res && (res.style.display = "block");
    setLastId(id);
  };

  const handleShowHideSubnotes = (id: string) => {
    let res = document.getElementById(id);
    if (res) {
      if (res.style.display === "block") {
        res.style.display = "none";
      } else {
        res.style.display = "block";
      }
    }
  };

  const renameList = async (oldName: string, newName: string) => {
    setProcessing(true);
    for (const el of notesList) {
      if (el["fields"]["note"]["en-US"]["tag"] === oldName) {
        try {
          const res = await updateNote(el.sys.id, el.sys.version, {
            ...getFromNestedObject(el, ["fields", "note", "en-US"]),
            tag: newName,
          });
          if (res) {
            runGet();
          }
        } catch (error: any) {
          console.log(error);
        } finally {
          setProcessing(false);
        }
      }
    }
  };

  useEffect(() => {
    runGet();
  }, []);

  if (sortedList) {
    const arr = getSortedArrayOfObjects(sortedList, sortBy, reverseSort);
    const arr2 = activeListName === "ALL" ? [...notesList] : ["GENERAL", ...notesLists];
    const indexToSplitLastListBlock = arr2.length - listBlock3.length - 1; // -1 is to calculate by index and not by length

    return (
      <>
        <Box pb="2rem" position="fixed" top="0" left="0" w="100%">
          <Container centerContent>
            <ButtonGroup spacing="6" pt="1rem">
              <Button size="xs" onClick={() => setTextInFocus((c: boolean) => !c)}>
                LR
              </Button>
              <Button
                size="sm"
                bg={isEditActive ? "yellow.300" : undefined}
                onClick={() => setIsEditActive((c: boolean) => !c)}
              >
                Edit
              </Button>
              <Button
                size="xs"
                onClick={() => {
                  modal3.onOpen();
                }}
              >
                {activeListName}
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  setSortedList(notesList);
                }}
              >
                Reset
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  modal2.onOpen();
                }}
              >
                Filter
              </Button>
            </ButtonGroup>
          </Container>
        </Box>
        <FilterForm
          isOpen={modal2.isOpen}
          onClose={modal2.onClose}
          allFilterValues={filterValues}
          filterFunc={filterNotes2}
          notesList={notesList}
        />
        <ListsForm
          isOpen={modal3.isOpen}
          onClose={modal3.onClose}
          notesLists={notesLists}
          filterFunc={filterNotes}
          renameListFunc={renameList}
          processing={processing}
        />

        <Accordion allowToggle maxW="md">
          {arr2.map((el: any, index: number) => {
            const isLastOfBlock = index === 0 || index === indexToSplitLastListBlock;
            return (
              <AccordionItem bg="white" key={el} borderBottom={isLastOfBlock ? "solid 2rem #e2e8f0" : ""}>
                <AccordionButton value={el} onClick={() => filterNotes(el, sortOptions.tag)}>
                  <Box flex="1" textAlign="center">
                    {el}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pr="0px" pl="0px">
                  <Grid templateColumns={"1fr"} gap={1}>
                    {/* <Grid templateColumns={sidebarLeft ? "35px 1fr" : "1fr 35px"} gap={1}> */}
                    <GridItem order={2}>
                      {/* <GridItem order={!sidebarLeft ? 1 : 2}> */}
                      <Stack spacing={0} ref={ref}>
                        {arr.map((obj: any) => {
                          const note = obj.fields.note["en-US"];
                          return note["is_daily"] ? (
                            <React.Fragment key={obj.sys["id"]}></React.Fragment>
                          ) : (
                            <Box key={obj.sys["id"]} maxW="md" color="black" letterSpacing="wide" fontSize="xs" p="1">
                              <Grid
                                templateColumns=" 6ch 1fr auto"
                                gap={1}
                                onClick={() => isEditActive && handleShowHideBtns(`${el}${obj.sys["id"]}note-options`)}
                              >
                                <GridItem
                                  color={!textInFocus ? "" : "gray.400"}
                                  p="0"
                                  textAlign="center"
                                  onClick={() => {
                                    if (!isEditActive) {
                                      setSortBy(sortOptions.category);
                                      setReverseSort((c: boolean) => !c);
                                    }
                                  }}
                                >
                                  {/* <Center h="100%">{note["category"]}</Center> */}
                                  <Center h="100%">
                                    {sidebarLeft ? (
                                      <Icon w={4} h={4} as={getIconByName(categoriesIcons[note["category"]])} />
                                    ) : (
                                      <Icon w={4} h={4} as={getIconOutlineByName(categoriesIcons[note["category"]])} />
                                    )}
                                  </Center>
                                </GridItem>
                                <GridItem
                                  p="0"
                                  color={textInFocus ? "" : "gray.400"}
                                  fontSize="md"
                                  {...propsByImportance(note["importance"])}
                                  style={{
                                    textDecoration: note["is_done"] ? "line-through" : undefined,
                                    opacity: note["is_done"] ? 0.3 : 1,
                                  }}
                                  onClick={() => {
                                    if (!isEditActive) {
                                      setSortBy(sortOptions.importance);
                                      setReverseSort((c: boolean) => !c);
                                    }
                                  }}
                                >
                                  {note["persons"] && (
                                    <span style={{ fontStyle: "italic", opacity: "0.5" }}>
                                      {`(${note["persons"]})`}{" "}
                                    </span>
                                  )}
                                  {note["text"]}
                                </GridItem>
                                <GridItem>
                                  {note["subtexts"] && note["subtexts"].length > 0 && (
                                    <Button
                                      bg="transparent"
                                      size="xs"
                                      onClick={() => handleShowHideSubnotes(`${el}${obj.sys["id"]}subnotes`)}
                                    >
                                      <TriangleDownIcon color="gray.500" />
                                    </Button>
                                  )}
                                </GridItem>
                              </Grid>
                              <Grid
                                templateColumns="6ch 20px 3fr 2fr 2fr"
                                gap={2}
                                color={!textInFocus ? "" : "gray.400"}
                              >
                                <GridItem
                                  opacity="0.3"
                                  p="0"
                                  textAlign="center"
                                  onClick={() => {
                                    if (!isEditActive) {
                                      setSortBy(sortOptions.createdOn);
                                      setReverseSort((c: boolean) => !c);
                                    }
                                  }}
                                >
                                  {formatDate(new Date(obj.sys["createdAt"]).getTime(), "D2+F.+M2")}
                                </GridItem>
                                <GridItem></GridItem>
                                <GridItem p="0">{note["day_period"]}</GridItem>
                                <GridItem p="0" textAlign="left">
                                  {note["duration"]} {note["duration_unit"]}
                                  {note["duration"] > 1 && "s"}
                                </GridItem>
                                <GridItem
                                  p="0"
                                  textAlign="left"
                                  onClick={() => {
                                    if (!isEditActive) {
                                      setSortBy(sortOptions.location);
                                      setReverseSort((c: boolean) => !c);
                                    }
                                  }}
                                >
                                  {note["location"]}
                                </GridItem>
                              </Grid>
                              {isEditActive && (
                                <ButtonGroup id={`${el}${obj.sys["id"]}note-options`} style={{ display: "None" }}>
                                  <Button
                                    size="xs"
                                    onClick={() =>
                                      handleCompleteNote(obj.sys.id, obj.sys.version, obj, !note["is_done"])
                                    }
                                    isLoading={processing}
                                  >
                                    {!note["is_done"] ? "Complete" : "Undo"}
                                  </Button>
                                  <Button
                                    size="xs"
                                    color="red"
                                    onClick={() => handleDeleteNote(obj.sys.id, obj.sys.version)}
                                    isLoading={processing}
                                  >
                                    Delete
                                  </Button>
                                  {!note["is_done"] && (
                                    <Button
                                      size="xs"
                                      onClick={() => {
                                        setterDefaulNoteVal({ ...note, id: obj.sys.id, version: obj.sys.version });
                                        onOpen();
                                      }}
                                      isLoading={processing}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </ButtonGroup>
                              )}
                              {note["subtexts"] && (
                                <Box
                                  pl="3rem"
                                  fontSize="sm"
                                  id={`${el}${obj.sys["id"]}subnotes`}
                                  style={{ display: "None" }}
                                >
                                  {note["subtexts"].map((el: any, i: number) => {
                                    return (
                                      <React.Fragment key={i}>
                                        <Box
                                          style={{
                                            textDecoration: el["is_done"] ? "line-through" : undefined,
                                            opacity: note["is_done"] ? 0.3 : 1,
                                          }}
                                          onClick={() =>
                                            isEditActive &&
                                            handleShowHideBtns(`${el}${obj.sys["id"]}subnote${i}options`)
                                          }
                                        >
                                          {el.text}
                                        </Box>
                                        {isEditActive && (
                                          <ButtonGroup
                                            id={`${el}${obj.sys["id"]}subnote${i}options`}
                                            style={{ display: "None" }}
                                          >
                                            <Button
                                              size="xs"
                                              onClick={() =>
                                                handleCompleteSubnote(
                                                  obj.sys.id,
                                                  obj.sys.version,
                                                  obj,
                                                  i,
                                                  !el["is_done"]
                                                )
                                              }
                                              isLoading={processing}
                                            >
                                              {!el["is_done"] ? "Complete" : "Undo"}
                                            </Button>
                                          </ButtonGroup>
                                        )}
                                        <Divider mt="0.5rem" mb="0.5rem" />
                                      </React.Fragment>
                                    );
                                  })}
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                      </Stack>

                      <Divider w="80%" ml="auto" mr="auto" mt={1} mb={4} borderColor="white" />

                      <Stack spacing={0} ref={ref} bg="gray.300">
                        {arr.map((obj: any) => {
                          const note = obj.fields.note["en-US"];
                          return !note["is_daily"] ? (
                            <React.Fragment key={obj.sys["id"]}></React.Fragment>
                          ) : (
                            <Box key={obj.sys["id"]} maxW="md" color="black" letterSpacing="wide" fontSize="xs" p="1">
                              <Grid
                                templateColumns=" 6ch 1fr auto"
                                gap={1}
                                onClick={() => isEditActive && handleShowHideBtns(`${el}${obj.sys["id"]}note-options`)}
                              >
                                <GridItem
                                  p="0"
                                  textAlign="center"
                                  color={!textInFocus ? "" : "gray.500"}
                                  onClick={() => {
                                    if (!isEditActive) {
                                      setSortBy(sortOptions.category);
                                      setReverseSort((c: boolean) => !c);
                                    }
                                  }}
                                >
                                  {/* <Center h="100%">{note["category"]}</Center> */}
                                  <Center h="100%">
                                    {sidebarLeft ? (
                                      <Icon w={4} h={4} as={getIconByName(categoriesIcons[note["category"]])} />
                                    ) : (
                                      <Icon w={4} h={4} as={getIconOutlineByName(categoriesIcons[note["category"]])} />
                                    )}
                                  </Center>
                                </GridItem>
                                <GridItem
                                  color={textInFocus ? "" : "gray.500"}
                                  p="0"
                                  fontSize="md"
                                  {...propsByImportance(note["importance"])}
                                  style={{
                                    textDecoration: note["is_done"] ? "line-through" : undefined,
                                    opacity: note["is_done"] ? 0.3 : 1,
                                  }}
                                  onClick={() => {
                                    if (!isEditActive) {
                                      setSortBy(sortOptions.importance);
                                      setReverseSort((c: boolean) => !c);
                                    }
                                  }}
                                >
                                  {note["persons"] && (
                                    <span style={{ fontStyle: "italic", opacity: "0.5" }}>
                                      {`(${note["persons"]})`}{" "}
                                    </span>
                                  )}
                                  {note["text"]}
                                </GridItem>
                                <GridItem>
                                  {note["subtexts"] && note["subtexts"].length > 0 && (
                                    <Button
                                      bg="transparent"
                                      size="xs"
                                      onClick={() => handleShowHideSubnotes(`${el}${obj.sys["id"]}subnotes`)}
                                    >
                                      <TriangleDownIcon color="gray.500" />
                                    </Button>
                                  )}
                                </GridItem>
                              </Grid>
                              <Grid
                                templateColumns="6ch 10px 3fr 2fr 2fr"
                                gap={2}
                                color={!textInFocus ? "" : "gray.500"}
                              >
                                <GridItem
                                  opacity="0.3"
                                  p="0"
                                  textAlign="center"
                                  onClick={() => {
                                    if (!isEditActive) {
                                      setSortBy(sortOptions.createdOn);
                                      setReverseSort((c: boolean) => !c);
                                    }
                                  }}
                                >
                                  {formatDate(new Date(obj.sys["createdAt"]).getTime(), "D2+F.+M2")}
                                </GridItem>
                                <GridItem></GridItem>
                                <GridItem p="0">{note["day_period"]}</GridItem>
                                <GridItem p="0" textAlign="left">
                                  {note["duration"]} {note["duration_unit"]}
                                  {note["duration"] > 1 && "s"}
                                </GridItem>
                                <GridItem
                                  p="0"
                                  textAlign="left"
                                  onClick={() => {
                                    if (!isEditActive) {
                                      setSortBy(sortOptions.location);
                                      setReverseSort((c: boolean) => !c);
                                    }
                                  }}
                                >
                                  {note["location"]}
                                </GridItem>
                              </Grid>
                              {isEditActive && (
                                <ButtonGroup id={`${el}${obj.sys["id"]}note-options`} style={{ display: "None" }}>
                                  <Button
                                    size="xs"
                                    onClick={() =>
                                      handleCompleteNote(obj.sys.id, obj.sys.version, obj, !note["is_done"])
                                    }
                                    isLoading={processing}
                                  >
                                    {!note["is_done"] ? "Complete" : "Undo"}
                                  </Button>
                                  <Button
                                    size="xs"
                                    color="red"
                                    onClick={() => handleDeleteNote(obj.sys.id, obj.sys.version)}
                                    isLoading={processing}
                                  >
                                    Delete
                                  </Button>
                                  {!note["is_done"] && (
                                    <Button
                                      size="xs"
                                      onClick={() => {
                                        setterDefaulNoteVal({ ...note, id: obj.sys.id, version: obj.sys.version });
                                        onOpen();
                                      }}
                                      isLoading={processing}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </ButtonGroup>
                              )}
                              {note["subtexts"] && (
                                <Box
                                  pl="3rem"
                                  fontSize="sm"
                                  id={`${el}${obj.sys["id"]}subnotes`}
                                  style={{ display: "None" }}
                                >
                                  {note["subtexts"].map((el: any, i: number) => {
                                    return (
                                      <React.Fragment key={i}>
                                        <Box
                                          style={{
                                            textDecoration: el["is_done"] ? "line-through" : undefined,
                                            opacity: note["is_done"] ? 0.3 : 1,
                                          }}
                                          onClick={() =>
                                            isEditActive &&
                                            handleShowHideBtns(`${el}${obj.sys["id"]}subnote${i}options`)
                                          }
                                        >
                                          {el.text}
                                        </Box>
                                        {isEditActive && (
                                          <ButtonGroup
                                            id={`${el}${obj.sys["id"]}subnote${i}options`}
                                            style={{ display: "None" }}
                                          >
                                            <Button
                                              size="xs"
                                              onClick={() =>
                                                handleCompleteSubnote(
                                                  obj.sys.id,
                                                  obj.sys.version,
                                                  obj,
                                                  i,
                                                  !el["is_done"]
                                                )
                                              }
                                              isLoading={processing}
                                            >
                                              {!el["is_done"] ? "Complete" : "Undo"}
                                            </Button>
                                          </ButtonGroup>
                                        )}
                                        <Divider mt="0.5rem" mb="0.5rem" />
                                      </React.Fragment>
                                    );
                                  })}
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                      </Stack>
                    </GridItem>
                    {/* <GridItem order={2}>
                      <GridItem order={sidebarLeft ? 1 : 2}>
                      <CategoriesSidebar categoriesSet={categories} filterFunc={filterForCategoriesSidebar} />
                    </GridItem> */}
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </>
    );
  } else {
    return (
      <Stack spacing={6}>
        <Text>No notes</Text>
      </Stack>
    );
  }
};

export default NotesList;
