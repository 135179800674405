import PrivateLayoutContainer from "layouts/PrivateLayoutContainer";
import PublicLayoutContainer from "layouts/PublicLayoutContainer";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import PrivateRoutes from "routes/PrivateRoutes";
import PublicRoutes from "routes/PublicRoutes";
import LoaderFullPage from "components/LoaderFullPage";
import { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "contexts/UserInfoProvider";
import tokenUtils from "security/token";
import { getMyInfo } from "api/users";
import { IUserInfo, IUserInfoFetched } from "interfaces/users";
import getOnlyCommonAttrs from "utils/getOnlyCommonAttrs";
import TopBar from "components/menus/TopBar";

function App() {
  const [userInfoState, setUserInfoState] = useContext(UserInfoContext);
  const [fullPageLoader, setFullPageLoader] = useState(false);

  useEffect(() => {
    // let token;
    // if (!userInfoState.token) {
    //   token = tokenUtils.getLocalToken();
    // }
    // async function getMyInfoOrRemoveLocalToken(token: string | null | undefined) {
    //   if (token) {
    //     try {
    //       setFullPageLoader(true);
    //       const result = await getMyInfo();
    //       const fetchedUserInfo: IUserInfoFetched = result.data;
    //       const adjustedUserInfo: IUserInfoFetched = getOnlyCommonAttrs(fetchedUserInfo, userInfoState);
    //       let newUserInfoState: IUserInfo = {
    //         ...userInfoState,
    //         ...adjustedUserInfo,
    //         isAdmin: fetchedUserInfo.is_admin,
    //         isAuthenticated: true,
    //         token: token,
    //       };
    //       setUserInfoState({ ...newUserInfoState });
    //     } catch (error: any) {
    //       tokenUtils.removeLocalToken();
    //     } finally {
    //       setFullPageLoader(false);
    //     }
    //   }
    // }
    // getMyInfoOrRemoveLocalToken(token);
  }, []);

  return (
    <BrowserRouter>
      <PrivateLayoutContainer>
        {/* <TopBar /> */}
        <PrivateRoutes />
      </PrivateLayoutContainer>
      {/* <PublicLayoutContainer>
        {fullPageLoader && <LoaderFullPage />}
        <PublicRoutes />
      </PublicLayoutContainer> */}
    </BrowserRouter>
  );
}

export default App;
