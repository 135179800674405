import {
  Box,
  Button,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

const path = ["fields", "note", "en-US", "tag"];

const ListsForm = ({ isOpen, onClose, notesLists, filterFunc, renameListFunc, processing }: any) => {
  const [oldListName, setOldListName] = useState("");
  const [newListName, setNewListName] = useState("");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Notes Lists</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box>Change list name</Box>
          <Grid templateColumns="1fr 1fr auto" gap={1} pb="0.5rem">
            <GridItem>
              <Select placeholder="Select list" onChange={(e: any) => setOldListName(e.target.value)}>
                {notesLists.map((el: any) => {
                  return (
                    <option key={el} value={el}>
                      {el}
                    </option>
                  );
                })}
              </Select>
            </GridItem>
            <GridItem>
              <Input placeholder="New name" value={newListName} onChange={(e: any) => setNewListName(e.target.value)} />
            </GridItem>
            <GridItem>
              <Button
                isLoading={processing}
                onClick={() => oldListName && newListName && renameListFunc(oldListName, newListName)}
              >
                OK
              </Button>
            </GridItem>
          </Grid>
          <VStack>
            <Button
              w="100%"
              key="ALL"
              onClick={() => {
                filterFunc("ALL", path);
                onClose();
              }}
            >
              ALL
            </Button>
            <Button
              w="100%"
              key="GENERAL"
              onClick={() => {
                filterFunc("GENERAL", path);
                onClose();
              }}
            >
              GENERAL
            </Button>
            {notesLists.map((el: any) => {
              return (
                <Button
                  key={el}
                  w="100%"
                  onClick={() => {
                    filterFunc(el, path);
                    onClose();
                  }}
                >
                  {el}
                </Button>
              );
            })}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ListsForm;
