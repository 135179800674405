import { UserInfoContext } from "contexts/UserInfoProvider";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import UserHome from "views/private/UserHome";
import UserSettings from "views/private/UserSettings";

const PrivateRoutes = () => {
  const [userInfoState, setUserInfoState] = useContext(UserInfoContext);

  // if (userInfoState.isAuthenticated) {
  //   return (
  //     <Routes>
  //       <Route path="/home" element={<UserHome />} />
  //       <Route path="/settings" element={<UserSettings />} />
  //       <Route path="*" element={<Navigate to={"/home"} />} />
  //     </Routes>
  //   );
  // }
  // return <></>;

  return (
    <Routes>
      <Route path="/home" element={<UserHome />} />
      <Route path="/settings" element={<UserSettings />} />
      <Route path="*" element={<Navigate to={"/home"} />} />
    </Routes>
  );
};

export default PrivateRoutes;
