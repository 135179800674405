import API_URL from "config/settings";


let ENDPOINTS = {
  notes: `${API_URL}`,
  createNote: `${API_URL}`,
  updateNote: `${API_URL}`,
  deleteNote: `${API_URL}`,
  login: `${API_URL}/token`,
  myInfo: `${API_URL}/my-info`,
  fieldsRegisterUser: `${API_URL}/user/fields/register`,
  registerUser: `${API_URL}/user/register`,
  users: `${API_URL}/users`,
  usernames: `${API_URL}/usernames`,
  userStatuses: `${API_URL}/user-statuses`,
  userStatusesDropdown: `${API_URL}/user-statuses/dropdown`,
  deleteUser: `${API_URL}/user/delete`,
  activateUserByAdmin: `${API_URL}/user/activate`,
  deactivateUserByAdmin: `${API_URL}/user/deactivate`,
  updateUserInfoByAdmin: `${API_URL}/user/update`,
  updateUserPasswordByAdmin: `${API_URL}/user/update/password`,
  permissions: `${API_URL}/permissions/all`,
  permissionsCreateByAdmin: `${API_URL}/permissions/create`,
  fieldsPermissionsCreateByAdmin: `${API_URL}/permissions/fields/create`,
  permissionsUpdateByAdmin: `${API_URL}/permissions/update`,
  permissionsDeleteByAdmin: `${API_URL}/permissions/delete`,
};

export default ENDPOINTS;
