import { Box, useDisclosure } from "@chakra-ui/react";
import NewNoteButton from "components/buttons/NewNoteButton";
import NewNoteForm from "components/forms/NewNoteForm";
import NotesList from "components/NotesList";
import { useState } from "react";

const UserHome = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [defaulNoteVal, setDefaulNoteVal] = useState(null);
  const [notesLists, setNotesLists] = useState(null);

  const setterDefaulNoteVal = (obj: any) => {
    setDefaulNoteVal(obj);
  };

  const setterNotesLists = (arr: any) => {
    setNotesLists(arr);
  };

  return (
    <Box w="100%" pt="4rem">
      {/* to reload notes list change key on modal open close */}
      <NotesList
        key={"" + isOpen}
        onOpen={onOpen}
        setterDefaulNoteVal={setterDefaulNoteVal}
        globalNotesLists={setterNotesLists}
      />
      <NewNoteButton onClick={onOpen} setterDefaulNoteVal={setterDefaulNoteVal} />
      <NewNoteForm
        key={Date.now()}
        isOpen={isOpen}
        onClose={onClose}
        defaulNoteVal={defaulNoteVal}
        notesLists={notesLists}
      />
    </Box>
  );
};

export default UserHome;
