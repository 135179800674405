import { Center, Heading, Stack } from "@chakra-ui/react";

const UserSettings = () => {
  return (
    <Center w="100vw" h="100vh">
      <Stack spacing={6} textAlign="center">
        <Heading>User settings WELCOME</Heading>
      </Stack>
    </Center>
  );
};

export default UserSettings;
