const currentEnv = process.env.NODE_ENV;
// let apiURL;

// if (currentEnv === "production") {
//   apiURL = "http://localhost:8000";
// } else {
//   apiURL = "http://localhost:8000";
//   console.log("******** DEVELOPMENT MODE - backend is local, at: " + apiURL + " ********");
// }

// this does not receive unpublished items, so we would always need to create and then again publish a note
// const apiUrlGet =
//   "https://cdn.contentful.com/spaces/gadqn6aw98eb/environments/master/entries";

const apiUrl = "https://api.contentful.com/spaces/gadqn6aw98eb/environments/master/entries";

const API_URL = apiUrl;

export default API_URL;
