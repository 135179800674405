import axios from "axios";
import ENDPOINTS from "api/endpoints";

// this does not receive unpublished items, so we would always need to create and then again publish a note
// const get_token = "access_token=Fs16Yt4yvtEuEUUxcTwFDVdLM2xr1r4YOLFjGSOyY4g";
const post_token = "access_token=CFPAT-398XctqcEKm6iJAsATRbSZsnmWSVo7pwIHnBuHl7XkY";

export const getNotes = async () => {
  return await axios.get(`${ENDPOINTS.notes}?${post_token}`);
};

export const createNote = async (obj: any) => {
  return await axios.post(`${ENDPOINTS.createNote}?${post_token}`, obj, {
    headers: {
      "X-Contentful-Content-Type": "note",
    },
  });
};

export const updateNote = async (entryId: string, version: number, obj: any) => {
  return await axios.patch(
    `${ENDPOINTS.updateNote}/${entryId}?${post_token}`,
    [{ op: "replace", path: "/fields/note/en-US", value: obj }],
    {
      headers: {
        "Content-Type": "application/json-patch+json",
        "X-Contentful-Version": version,
      },
    }
  );
};

export const deleteNote = async (entryId: string, version: number) => {
  return await axios.delete(
    `${ENDPOINTS.deleteNote}/${entryId}?${post_token}`,
    {
      headers: {
        "X-Contentful-Version": version,
      },
    }
  );
};
