import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import getFromNestedObject from "utils/getFromNestedObject";

const sortOptions: any = {
  createdOn: ["sys", "createdAt"],
  tag: ["fields", "note", "en-US", "tag"],
  importance: ["fields", "note", "en-US", "importance"],
  category: ["fields", "note", "en-US", "category"],
  dayPeriod: ["fields", "note", "en-US", "day_period"],
  duration: ["fields", "note", "en-US", "duration"],
  location: ["fields", "note", "en-US", "location"],
};

const FilterForm = ({ isOpen, onClose, allFilterValues, notesList, filterFunc, defaulNoteVal }: any) => {
  const [filterValues, setFilterValues]: any = useState(allFilterValues);
  const [valsToFilter, setValsToFilter]: any = useState({});

  const addVal = (key: string, val: string) => {
    if (Object.keys(valsToFilter).includes(key)) {
      setValsToFilter((c: any) => {
        let o = { ...c };
        o[key].add(val);
        checkRemaining(o);
        return { ...o };
      });
    } else {
      setValsToFilter((c: any) => {
        let o = { ...c, [key]: new Set([val]) };
        checkRemaining(o);
        return o;
      });
    }
  };

  const removeVal = (key: string, val: string) => {
    if (Object.keys(valsToFilter).includes(key)) {
      setValsToFilter((c: any) => {
        let o = { ...c };
        o[key].delete(val);
        if (o[key].size === 0) {
          delete o[key];
        }
        checkRemaining(o);
        return { ...o };
      });
    }
  };

  const checkRemaining = (object: any) => {
    let nl = [...notesList];
    Object.entries(object).map(([key, arr]: any) => {
      nl = nl.filter((el: any) => {
        return arr.has(getFromNestedObject(el, sortOptions[key]));
      });
    });
    // prepare filter categories and values
    let fvalues: any = {
      tag: new Set(),
      importance: new Set(),
      category: new Set(),
      location: new Set(),
    };
    nl.map((obj: any) => {
      const note = obj.fields.note["en-US"];
      fvalues["tag"].add(note["tag"]);
      fvalues["importance"].add(note["importance"]);
      fvalues["category"].add(note["category"]);
      fvalues["location"].add(note["location"]);
    });
    for (let key in fvalues) {
      // remove if only one left (meaning it is from the same item)
      fvalues[key] = new Set([...fvalues[key]].sort());
    }
    for (let key in fvalues) {
      // sort items in set
      fvalues[key] = new Set([...fvalues[key]].sort());
    }
    setFilterValues((c: any) => ({ ...fvalues }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Filters</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Grid key={Date.now()} templateColumns="1fr 1fr 1fr 1fr" gap={1}>
            {Object.entries(filterValues).map(([key, set]: any) => {
              return (
                <GridItem key={key} p="0" textAlign="center" color="gray.300">
                  <VStack alignItems="flex-start">
                    <Text>{key}</Text>
                    {[...set].map((el: string) => {
                      const isSelected = valsToFilter[key] && valsToFilter[key].has(el);
                      return (
                        <Button
                          key={el}
                          variant={isSelected ? "solid" : "outline"}
                          size="xs"
                          onClick={() => (isSelected ? removeVal(key, el) : addVal(key, el))}
                        >
                          {el}
                        </Button>
                      );
                    })}
                  </VStack>
                </GridItem>
              );
            })}
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              filterFunc(valsToFilter);
              onClose();
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FilterForm;
