import { ArrowBackIcon, ArrowForwardIcon, CheckIcon, RepeatIcon } from "@chakra-ui/icons";

import {
  Button,
  FormControl,
  FormErrorMessage,
  VStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Grid,
  GridItem,
  ButtonGroup,
  Icon,
  Divider,
  InputGroup,
  InputRightElement,
  Center,
  Spinner,
  Text,
  InputLeftElement,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Stack,
  Radio,
  Box,
  Select,
} from "@chakra-ui/react";
import { createNote, updateNote } from "api/notes";
import { useEffect, useRef, useState } from "react";
import { getIconByName, getIconOutlineByName } from "./base_new_note/getIconsDynamically";

const NewNoteForm = ({ isOpen, onClose, defaulNoteVal, notesLists }: any) => {
  const [visibleSection, setVisibleSection] = useState([true, false, false, false, false, false, false, false]);
  const initialRef: any = useRef();
  const finalRef: any = useRef();
  const [isError, setIsError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [tag, setTag] = useState("");
  const [isDaily, setIsDaily] = useState(false);
  const [personName, setPersonName] = useState("");
  const [noteText, setNoteText] = useState("");
  const [noteSubtexts, setNoteSubtexts]: any = useState([]);
  const [importance, setImportance] = useState(0);
  const [category, setCategory] = useState("");
  const [dayPeriod, setDayPeriod] = useState("");
  const [duration, setDuration]: any = useState("");
  const [durationUnit, setDurationUnit] = useState("");
  const [location, setLocation] = useState("");
  const durationUnits = ["min", "h", "days", "weeks", "months", "years"];
  const locations = ["anywhere", "home", "work", "school", "city", "local", "mosque"];
  //   {name: icon}
  const categories = {
    left: {
      do: "IoHandRight",
      watch: "IoEye",
      listen: "IoEar",
      read: "IoBook",
      write: "IoCreate",
      learn: "IoSchool",
    },
    right: {
      check: "IoHelp",
      info: "IoInformationCircle",
      travel: "IoCar",
      shop: "IoCart",
      call: "IoCall",
      message: "IoChatbox",
      mail: "IoMail",
    },
  };
  //   {name: icon}
  const dayPeriods = {
    left: {
      "early morning": "BsSunrise",
      morning: "IoContrastOutline",
      noon: "IoStopwatch",
      afternoon: "IoContrast",
      evening: "BsSunset",
      night: "IoMoon",
    },
    right: {
      "before work": "IoWalk",
      "at work": "IoBusiness",
      "after work": "IoFootsteps",
      "free time": "IoLogoTwitter",
      weekend: "IoCalendar",
      holiday: "GiPalmTree",
    },
  };

  const handleSubmitNewNote = async () => {
    try {
      setIsError(false);
      setProcessing(true);
      const res = await createNote({
        fields: {
          note: {
            "en-US": {
              text: noteText,
              subtexts: noteSubtexts,
              is_done: false,
              tag: tag,
              is_daily: isDaily,
              persons: personName,
              importance: importance,
              category: category,
              day_period: dayPeriod,
              duration: duration,
              duration_unit: duration ? durationUnit : "",
              location: location,
            },
          },
        },
      });

      if (res) {
        onClose();
      }
    } catch (error: any) {
      console.log(error);
      setIsError(true);
    } finally {
      setProcessing(false);
    }
  };

  const handleUpdateNote = async () => {
    try {
      setIsError(false);
      setProcessing(true);
      const res = await updateNote(defaulNoteVal["id"], defaulNoteVal["version"], {
        text: noteText,
        subtexts: noteSubtexts,
        is_done: false,
        tag: tag,
        is_daily: isDaily,
        persons: personName,
        importance: importance,
        category: category,
        day_period: dayPeriod,
        duration: duration,
        duration_unit: duration ? durationUnit : "",
        location: location,
      });

      if (res) {
        onClose();
      }
    } catch (error: any) {
      console.log(error);
      setIsError(true);
    } finally {
      setProcessing(false);
    }
  };

  const goBack = (currentSectionIndex: number) => {
    setIsError(false); // restart error message
    setVisibleSection((c: any) => {
      let newArr = c;
      newArr[currentSectionIndex] = false;
      newArr[currentSectionIndex - 1] = true;
      return [...newArr];
    });
  };

  const goNext = (currentSectionIndex: number) => {
    setIsError(false); // restart error message
    setVisibleSection((c: any) => {
      let newArr = c;
      newArr[currentSectionIndex] = false;
      newArr[currentSectionIndex + 1] = true;
      return [...newArr];
    });
  };

  useEffect(() => {
    if (defaulNoteVal) {
      setTag(defaulNoteVal["tag"]);
      setIsDaily(defaulNoteVal["is_daily"]);
      setPersonName(defaulNoteVal["persons"]);
      setNoteText(defaulNoteVal["text"]);
      setNoteSubtexts(defaulNoteVal["subtexts"]);
      setImportance(defaulNoteVal["importance"]);
      setCategory(defaulNoteVal["category"]);
      setDayPeriod(defaulNoteVal["day_period"]);
      setDuration(defaulNoteVal["duration"]);
      setDurationUnit(defaulNoteVal["duration_unit"]);
      setLocation(defaulNoteVal["location"]);
    }
  }, [defaulNoteVal]);

  return (
    <>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {visibleSection[0] && (
            <>
              <ModalHeader>Tag</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <InputGroup pb="0.5rem">
                    {/* <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={getIconByName("IoPerson")} color="gray.300" />}
                    /> */}
                    <Input
                      autoComplete="off"
                      name="tag"
                      placeholder="TAG"
                      value={tag}
                      onChange={(e: any) => {
                        setTag(e.target.value);
                      }}
                    />
                  </InputGroup>

                  {notesLists && (
                    <Select placeholder="Select existing" onChange={(e: any) => setTag(e.target.value)}>
                      <option key="GENERAL" value="GENERAL">
                        GENERAL
                      </option>
                      {notesLists.map((el: any) => {
                        return (
                          <option key={el} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <ButtonGroup w="100%" spacing={5} justifyContent="center">
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => handleUpdateNote()}
                    >
                      Finish
                    </Button>
                  )}
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => goNext(0)}
                      rightIcon={<ArrowForwardIcon />}
                    >
                      Next
                    </Button>
                  )}
                  <Button width="100px" bg={tag ? "yellow.300" : undefined} onClick={() => goNext(0)}>
                    {tag ? "Next" : "Skip"}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
          {visibleSection[1] && (
            <>
              <ModalHeader>Is a person in note?</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl isInvalid={isError}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={getIconByName("IoPerson")} color="gray.300" />}
                    />
                    <Input
                      autoComplete="off"
                      name="person"
                      placeholder="Person name"
                      value={personName}
                      onChange={(e: any) => {
                        setIsError(false);
                        setPersonName(e.target.value);
                      }}
                    />
                  </InputGroup>
                  {isError && <FormErrorMessage>Enter person or click NO button</FormErrorMessage>}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <ButtonGroup w="100%" spacing={5} justifyContent="center">
                  <Button width="100px" onClick={() => goNext(1)}>
                    No
                  </Button>
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => handleUpdateNote()}
                    >
                      Finish
                    </Button>
                  )}
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => goNext(1)}
                      rightIcon={<ArrowForwardIcon />}
                    >
                      Next
                    </Button>
                  )}
                  <Button
                    width="100px"
                    bg={personName ? "yellow.300" : undefined}
                    onClick={() => {
                      !personName.trim() ? setIsError(true) : goNext(1);
                    }}
                  >
                    Yes
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
          {visibleSection[2] && (
            <>
              <ModalHeader>Your note:</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Button size="sm" bg={isDaily ? "yellow.300" : "gray.100"} onClick={() => setIsDaily((c: boolean) => !c)}>
                  Is daily
                </Button>
                <FormControl isInvalid={isError}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={getIconByName("IoClipboard")} color="gray.300" />}
                    />
                    <Input
                      autoComplete="off"
                      ref={initialRef}
                      name="text"
                      placeholder="Note"
                      value={noteText}
                      onChange={(e: any) => {
                        setIsError(false);
                        setNoteText(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <Box pl="1rem">
                    {noteSubtexts &&
                      noteSubtexts.map((el: any, i: number) => {
                        return (
                          <InputGroup key={i}>
                            <Input
                              autoComplete="off"
                              name="subtext"
                              placeholder="subnote"
                              value={el.text}
                              onChange={(e: any) => {
                                setNoteSubtexts((c: any) => {
                                  let newArr: any = [...c];
                                  newArr[i]["text"] = e.target.value;
                                  return newArr;
                                });
                              }}
                            />
                            <Button
                              onClick={() =>
                                setNoteSubtexts((c: any) => {
                                  let newArr = [...c];
                                  newArr.splice(i, 1);
                                  return newArr;
                                })
                              }
                            >
                              X
                            </Button>
                          </InputGroup>
                        );
                      })}
                  </Box>
                  <Button onClick={() => setNoteSubtexts((c: any) => [...c, { text: "" }])}>+ Add subnote</Button>
                  {isError && <FormErrorMessage>Note cannot be empty</FormErrorMessage>}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Grid templateColumns="1fr 1fr" w="100%">
                  <GridItem textAlign="left">
                    <Button leftIcon={<ArrowBackIcon />} onClick={() => goBack(2)}>
                      Back
                    </Button>
                  </GridItem>
                  <GridItem textAlign="right">
                    {defaulNoteVal && (
                      <Button
                        size="xs"
                        bg="blue.300"
                        color="white"
                        isLoading={processing}
                        onClick={() => handleUpdateNote()}
                      >
                        Finish
                      </Button>
                    )}
                    {defaulNoteVal && (
                      <Button
                        size="xs"
                        bg="blue.300"
                        color="white"
                        isLoading={processing}
                        onClick={() => goNext(2)}
                        rightIcon={<ArrowForwardIcon />}
                      >
                        Next
                      </Button>
                    )}
                    <Button
                      rightIcon={<ArrowForwardIcon />}
                      bg="yellow.300"
                      onClick={() => {
                        !noteText.trim() ? setIsError(true) : goNext(2);
                      }}
                    >
                      Next
                    </Button>
                  </GridItem>
                </Grid>
              </ModalFooter>
            </>
          )}
          {visibleSection[3] && (
            <>
              <ModalHeader>Importance:</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <VStack>
                  <Button
                    name="importance"
                    size="md"
                    variant="ghost"
                    width="200px"
                    fontStyle="italic"
                    fontWeight="normal"
                    isActive={defaulNoteVal && defaulNoteVal["importance"] === 10}
                    onClick={() => {
                      setImportance(10);
                      goNext(3);
                    }}
                  >
                    low
                  </Button>
                  <Button
                    name="importance"
                    size="md"
                    variant="ghost"
                    width="200px"
                    isActive={defaulNoteVal && defaulNoteVal["importance"] === 20}
                    onClick={() => {
                      setImportance(20);
                      goNext(3);
                    }}
                  >
                    normal
                  </Button>
                  <Button
                    name="importance"
                    size="md"
                    variant="ghost"
                    width="200px"
                    fontWeight="bold"
                    isActive={defaulNoteVal && defaulNoteVal["importance"] === 30}
                    onClick={() => {
                      setImportance(30);
                      goNext(3);
                    }}
                  >
                    high
                  </Button>
                  <Button
                    name="importance"
                    size="md"
                    variant="ghost"
                    width="200px"
                    fontWeight="bold"
                    color="red"
                    isActive={defaulNoteVal && defaulNoteVal["importance"] === 40}
                    onClick={() => {
                      setImportance(40);
                      goNext(3);
                    }}
                  >
                    urgent
                  </Button>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <ButtonGroup w="100%" justifyContent="left">
                  <Button leftIcon={<ArrowBackIcon />} onClick={() => goBack(3)}>
                    Back
                  </Button>
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => handleUpdateNote()}
                    >
                      Finish
                    </Button>
                  )}
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => goNext(3)}
                      rightIcon={<ArrowForwardIcon />}
                    >
                      Next
                    </Button>
                  )}
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
          {visibleSection[4] && (
            <>
              <ModalHeader>Category:</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Grid templateColumns="1fr 1fr" w="100%">
                  <GridItem textAlign="left">
                    {Object.entries(categories["left"]).map(([text, icon]: any) => {
                      return (
                        <Button
                          key={text}
                          name="category"
                          justifyContent="left"
                          size="md"
                          variant="ghost"
                          width="100%"
                          leftIcon={<Icon as={getIconOutlineByName(icon)} />}
                          isActive={defaulNoteVal && defaulNoteVal["category"] === text}
                          onClick={() => {
                            setCategory(text);
                            goNext(4);
                          }}
                        >
                          {text}
                        </Button>
                      );
                    })}
                  </GridItem>
                  <GridItem textAlign="left">
                    {Object.entries(categories["right"]).map(([text, icon]: any) => {
                      return (
                        <Button
                          key={text}
                          name="category"
                          justifyContent="left"
                          size="md"
                          variant="ghost"
                          width="100%"
                          leftIcon={<Icon as={getIconOutlineByName(icon)} />}
                          isActive={defaulNoteVal && defaulNoteVal["category"] === text}
                          onClick={() => {
                            setCategory(text);
                            goNext(4);
                          }}
                        >
                          {text}
                        </Button>
                      );
                    })}
                  </GridItem>
                </Grid>
              </ModalBody>

              <ModalFooter>
                <ButtonGroup w="100%" justifyContent="left">
                  <Button leftIcon={<ArrowBackIcon />} onClick={() => goBack(4)}>
                    Back
                  </Button>
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => handleUpdateNote()}
                    >
                      Finish
                    </Button>
                  )}
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => goNext(4)}
                      rightIcon={<ArrowForwardIcon />}
                    >
                      Next
                    </Button>
                  )}
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
          {visibleSection[5] && (
            <>
              <ModalHeader>When? (period of day):</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Grid templateColumns="1fr 10px 1fr" w="100%">
                  <GridItem textAlign="left">
                    {Object.entries(dayPeriods["left"]).map(([text, icon]: any) => {
                      return (
                        <Button
                          key={text}
                          name="day_period"
                          justifyContent="left"
                          size="md"
                          variant="ghost"
                          width="100%"
                          leftIcon={<Icon as={getIconOutlineByName(icon)} />}
                          isActive={defaulNoteVal && defaulNoteVal["day_period"] === text}
                          onClick={() => {
                            setDayPeriod(text);
                            goNext(5);
                          }}
                        >
                          {text}
                        </Button>
                      );
                    })}
                  </GridItem>
                  <GridItem>
                    <Divider orientation="vertical" />
                  </GridItem>
                  <GridItem textAlign="left">
                    {Object.entries(dayPeriods["right"]).map(([text, icon]: any) => {
                      return (
                        <Button
                          key={text}
                          name="day_period"
                          justifyContent="left"
                          size="md"
                          variant="ghost"
                          width="100%"
                          leftIcon={<Icon as={getIconOutlineByName(icon)} />}
                          isActive={defaulNoteVal && defaulNoteVal["day_period"] === text}
                          onClick={() => {
                            setDayPeriod(text);
                            goNext(5);
                          }}
                        >
                          {text}
                        </Button>
                      );
                    })}
                  </GridItem>
                </Grid>
              </ModalBody>

              <ModalFooter>
                <ButtonGroup w="100%" justifyContent="left">
                  <Button leftIcon={<ArrowBackIcon />} onClick={() => goBack(5)}>
                    Back
                  </Button>
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => handleUpdateNote()}
                    >
                      Finish
                    </Button>
                  )}
                  {defaulNoteVal && (
                    <Button
                      size="xs"
                      bg="blue.300"
                      color="white"
                      isLoading={processing}
                      onClick={() => goNext(5)}
                      rightIcon={<ArrowForwardIcon />}
                    >
                      Next
                    </Button>
                  )}
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
          {visibleSection[6] && (
            <>
              <ModalHeader>Duration (how long?):</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Grid templateColumns="1fr 1fr" w="100%" gap="1rem">
                  <GridItem display="flex" alignItems="center" justifyContent="center" position="relative">
                    <Box
                      maxHeight={250}
                      width={100}
                      overflowY="auto"
                      pt="1.5rem"
                      pb="1.5rem"
                      pl="3px"
                      pr="3px"
                      borderBottom="solid 1px"
                      borderBottomColor="gray.300"
                      borderTop="solid 1px"
                      borderTopColor="gray.300"
                      css={{ "::-webkit-scrollbar": { display: "none" }, scrollbarWidth: "none" }}
                    >
                      <Button
                        key={0}
                        name="duration"
                        size="md"
                        variant="ghost"
                        width="100%"
                        onClick={() => {
                          setDuration("");
                        }}
                      >
                        0
                      </Button>
                      <Button
                        key={0.5}
                        name="duration"
                        size="md"
                        variant="ghost"
                        width="100%"
                        isActive={(defaulNoteVal && defaulNoteVal["duration_unit"] == "0.5") || duration == "0.5"}
                        onClick={() => {
                          setDuration(0.5);
                        }}
                      >
                        1/2
                      </Button>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30].map((item: any) => {
                        return (
                          <Button
                            key={item}
                            name="duration"
                            size="md"
                            variant="ghost"
                            width="100%"
                            isActive={(defaulNoteVal && defaulNoteVal["duration_unit"] === item) || duration === item}
                            onClick={() => {
                              setDuration(item);
                            }}
                          >
                            {item}
                          </Button>
                        );
                      })}
                    </Box>
                    <Box
                      position="absolute"
                      left="0"
                      top="1px"
                      height="2.5rem"
                      width="100%"
                      backgroundImage="linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0))"
                    ></Box>
                    <Box
                      position="absolute"
                      left="0"
                      bottom="1px"
                      height="2.5rem"
                      width="100%"
                      backgroundImage="linear-gradient(to top, rgba(255,255,255,1), 10%, rgba(255,255,255,0))"
                    ></Box>
                  </GridItem>
                  <GridItem display="flex" alignItems="center" justifyContent="center" position="relative">
                    <Box
                      maxHeight={250}
                      width={100}
                      overflowY="auto"
                      pt="1rem"
                      pb="1rem"
                      pl="3px"
                      pr="3px"
                      borderBottom="solid 1px"
                      borderBottomColor="gray.300"
                      borderTop="solid 1px"
                      borderTopColor="gray.300"
                      css={{ "::-webkit-scrollbar": { display: "none" }, scrollbarWidth: "none" }}
                    >
                      {durationUnits.map((item: any) => {
                        return (
                          <Button
                            key={item}
                            name="duration_unit"
                            size="md"
                            variant="ghost"
                            width="100%"
                            isActive={
                              (defaulNoteVal && defaulNoteVal["duration_unit"] === item) || durationUnit === item
                            }
                            onClick={() => {
                              setDurationUnit(item);
                            }}
                          >
                            {item}
                          </Button>
                        );
                      })}
                    </Box>
                    <Box
                      position="absolute"
                      left="0"
                      top="1px"
                      height="1rem"
                      width="100%"
                      backgroundImage="linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0))"
                    ></Box>
                    <Box
                      position="absolute"
                      left="0"
                      bottom="1px"
                      height="1rem"
                      width="100%"
                      backgroundImage="linear-gradient(to top, rgba(255,255,255,1), 10%, rgba(255,255,255,0))"
                    ></Box>
                  </GridItem>
                </Grid>
              </ModalBody>

              <ModalFooter>
                <Grid templateColumns="1fr 1fr" w="100%">
                  <GridItem textAlign="left">
                    <Button leftIcon={<ArrowBackIcon />} onClick={() => goBack(6)}>
                      Back
                    </Button>
                    {defaulNoteVal && (
                      <Button
                        size="xs"
                        bg="blue.300"
                        color="white"
                        isLoading={processing}
                        onClick={() => handleUpdateNote()}
                      >
                        Finish
                      </Button>
                    )}
                    {defaulNoteVal && (
                      <Button
                        size="xs"
                        bg="blue.300"
                        color="white"
                        isLoading={processing}
                        onClick={() => goNext(6)}
                        rightIcon={<ArrowForwardIcon />}
                      >
                        Next
                      </Button>
                    )}
                  </GridItem>
                  <GridItem textAlign="right">
                    <Button
                      rightIcon={<ArrowForwardIcon />}
                      bg="yellow.300"
                      onClick={() => {
                        goNext(6);
                      }}
                    >
                      {duration && durationUnit ? "Next" : "Skip"}
                    </Button>
                  </GridItem>
                </Grid>
              </ModalFooter>
            </>
          )}
          {visibleSection[7] && (
            <>
              <ModalHeader>Where? (location):</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl isInvalid={isError}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={getIconByName("IoLocation")} color="gray.300" />}
                    />
                    <Input
                      autoComplete="off"
                      name="location"
                      value={location}
                      onChange={(e: any) => {
                        setLocation(e.target.value);
                        setIsError(false);
                      }}
                    />
                  </InputGroup>
                  {isError && <FormErrorMessage>Enter person or click NO button</FormErrorMessage>}
                </FormControl>
                {locations.map((item: any) => {
                  return (
                    <Button
                      key={item}
                      name="location"
                      justifyContent="center"
                      size="md"
                      variant="ghost"
                      width="100%"
                      onClick={() => {
                        setLocation(item);
                        setIsError(false);
                      }}
                    >
                      {item}
                    </Button>
                  );
                })}
              </ModalBody>

              <ModalFooter>
                {isError && "some error occured"}
                <Grid templateColumns="1fr 1fr" w="100%">
                  <GridItem textAlign="left">
                    <Button leftIcon={<ArrowBackIcon />} onClick={() => goBack(7)}>
                      Back
                    </Button>
                    {defaulNoteVal && (
                      <Button
                        size="xs"
                        bg="blue.300"
                        color="white"
                        isLoading={processing}
                        onClick={() => handleUpdateNote()}
                      >
                        Finish
                      </Button>
                    )}
                    {defaulNoteVal && (
                      <Button
                        size="xs"
                        bg="blue.300"
                        color="white"
                        isLoading={processing}
                        onClick={() => goNext(7)}
                        rightIcon={<ArrowForwardIcon />}
                      >
                        Next
                      </Button>
                    )}
                  </GridItem>
                  <GridItem textAlign="right">
                    <Button
                      rightIcon={<CheckIcon />}
                      bg="yellow.300"
                      disabled={location ? false : true}
                      onClick={() => {
                        if (defaulNoteVal) {
                          handleUpdateNote();
                        } else {
                          goNext(7);
                          handleSubmitNewNote();
                        }
                      }}
                    >
                      Finish
                    </Button>
                  </GridItem>
                </Grid>
              </ModalFooter>
            </>
          )}
          {visibleSection[8] && (
            <>
              <ModalHeader></ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Center height={200}>
                  {processing && <Spinner color="blue.500" emptyColor="gray.200" size="lg" speed="0.8s" />}
                  {isError && (
                    <Text size="md" color="red.500">
                      Some error occured
                    </Text>
                  )}
                </Center>
              </ModalBody>

              <ModalFooter>
                <Grid templateColumns="1fr 1fr" w="100%">
                  <GridItem textAlign="left">
                    {!processing && (
                      <Button leftIcon={<ArrowBackIcon />} onClick={() => goBack(8)}>
                        Back
                      </Button>
                    )}
                  </GridItem>
                  <GridItem textAlign="right">
                    <Button
                      rightIcon={<RepeatIcon />}
                      disabled={processing}
                      onClick={() => {
                        handleSubmitNewNote();
                      }}
                    >
                      Try again
                    </Button>
                  </GridItem>
                </Grid>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewNoteForm;
