import { UserInfoContext } from "contexts/UserInfoProvider";
import { useContext } from "react";
import { Container } from "@chakra-ui/react";

const PrivateLayoutContainer = ({ children }: any) => {
  const [userInfoState, setUserInfoState] = useContext(UserInfoContext);

  // if (!userInfoState.isAdmin && userInfoState.isAuthenticated) {
  //   return <Container centerContent color="gray.500">{children}</Container>;
  // }
  // return <></>;

  return <Container centerContent color="gray.500" pb="30vh">{children}</Container>;
};

export default PrivateLayoutContainer;
