const getSortedArrayOfObjects = (arrayOfObjs: any, attrPath: string[], reverse: boolean) => {
  /**
   *
   * @param attr is object attribute with value for comparison
   * @returns new sorted array
   *
   */
  function compare(objA: any, objB: any) {
    let a = objA;
    let b = objB;
    // get nested attr
    for (let i = 0; i < attrPath.length; i++) {
      a = a[attrPath[i]];
      b = b[attrPath[i]];
    }

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  let sortedArr = arrayOfObjs;
  sortedArr.sort(compare);
  if (reverse) {
    return sortedArr.reverse();
  } else {
    return sortedArr;
  }
};

export default getSortedArrayOfObjects;
