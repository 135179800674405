import { AddIcon } from "@chakra-ui/icons";
import { Box, IconButton } from "@chakra-ui/react";

const NewNoteButton = ({ onClick, setterDefaulNoteVal }: any) => {
  return (
    <Box position="fixed" bottom="0" right="0" p="1rem 1rem">
      <IconButton
        size="lg"
        aria-label="Add-new"
        icon={<AddIcon />}
        variant="outline"
        borderRadius="full"
        bg="yellow.300"
        onClick={() => {
          setterDefaulNoteVal(null);
          onClick();
        }}
      />
    </Box>
  );
};

export default NewNoteButton;
