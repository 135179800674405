export function formatDate(dateNr: number, pattern: string) {
    //     """
    //     Example document nr.:   P00123/21
    //     Pattern:                FP+C5+F/+Y2
  
    //     FP = Fixed character P      => P
    //     F/ = Fixed character /      => /
    //     C5 = Counter (5 cifers)     => 00003
    //     Y2 = Year 2 cifers          => 21
    //     Y4 = Year 4 cifers          => 2021
    //     M2 = Month 2 cifers         => 05
    //     W2 = Week 2 cifers          => 47
    //     D2 = Day in month 2 cifers  => 17
    //     h2 = Hour (in 24h) 2 cifers => 13
    //     m2 = minute 2 cifers        => 59
    //     s2 = second 2 cifers        => 48
    //     """
  
    const ptrnElements = pattern.split("+");
    let ptrnPairs: any = [];
    const split_at = 1;
  
    ptrnElements.forEach((el: any) => {
      ptrnPairs.push([el[0], el.slice(1)]);
    });
    //  now we sould have a list like:
    //  ptrn_pairs = [["F", "P"], ["C", "5"], ["F", "/"], ["Y", "2"]]
  
    let formattedDate = "";
    const now = new Date(dateNr);
    // const now = Date.now();
  
    ptrnPairs.forEach((pair: any) => {
      let code = pair[0];
      let value = pair[1];
  
      if (code == "F") {
        formattedDate += String(value);
      } else if (code == "Y") {
        formattedDate += zfill(String(new Date(now).getFullYear()).slice(-Number(value)), Number(value));
      } else if (code == "M") {
        formattedDate += zfill(String(new Date(now).getMonth() + 1), Number(value));
      } else if (code == "D") {
        formattedDate += zfill(String(new Date(now).getDate()), Number(value));
      } else if (code == "h") {
        formattedDate += zfill(String(new Date(now).getHours()), Number(value));
      } else if (code == "m") {
        formattedDate += zfill(String(new Date(now).getMinutes()), Number(value));
      } else if (code == "s") {
        formattedDate += zfill(String(new Date(now).getSeconds()), Number(value));
      }
    });
  
    return formattedDate;
  }
  
  function zfill(num: string, len: number) {
    return (Array(len).join("0") + num).slice(-len);
  }
  